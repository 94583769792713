import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import GatsbyImage from "gatsby-image"
import Layout from "../components/Layout"
import LayoutContainer from "../components/LayoutContainer"
import { CONTACT_FORM_ID, CONTACT_US_ROUTE, FOR_SALE_ROUTE } from "../constants"
import DarkBackgroundSVG from "../components/DarkBackgroundSVG"
import { convertCentsToCurrency } from "../utils"
import DisplayDialog, { ActiveItem } from "../components/DisplayDialog"

export default () => {
  const [activeItem, setActiveItem] = React.useState<ActiveItem>(null)
  const pageData = useStaticQuery(graphql`
    query {
      allProduct(
        sort: { fields: category }
        filter: { name: { ne: "DUMMY_DO_NOT_DELETE" } }
      ) {
        edges {
          node {
            name
            description
            category
            minPrice
            productImage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const products = pageData.allProduct.edges.map((item: any) => item.node)
  return (
    <Layout
      pageTitle="Products For Sale"
      pageURL={FOR_SALE_ROUTE}
      pageDescription="Explore carts and other products QC Cart Craft has available for purchase."
    >
      <header className="text-white relative py-12 bg-teal-800">
        <DarkBackgroundSVG />
        <LayoutContainer>
          <div className="lg:max-w-screen-lg m-auto max-w-screen-md relative z-10 text-center">
            <h6 className="tracking-widest text-teal-200 text-sm mb-3">
              PRODUCTS FOR SALE
            </h6>
            <h1 className="text-5xl sm:text-6xl font-bold leading-none mb-4">
              Check Out What's In Stock
            </h1>
            <p className="text-white text-sm">
              Explore carts and other products we have available for purchase.{" "}
              <Link to={CONTACT_US_ROUTE} className="underline">
                Contact us
              </Link>{" "}
              for more information.
            </p>
          </div>
        </LayoutContainer>
      </header>
      <section className="py-8">
        <LayoutContainer>
          <div className="lg:max-w-screen-xl m-auto">
            {products.length > 0 ? (
              <div className="flex flex-wrap -mx-4">
                {products.map((product: any, index: number) => {
                  const initialContactMessage = `I am interested in learning more about your product - ${product.name}`
                  function handleClick() {
                    setActiveItem({
                      fluidObject: product.productImage.childImageSharp.fluid,
                      name: product.name,
                      description: product.description,
                      price: product.minPrice,
                      initialContactMessage,
                    })
                  }
                  return (
                    <div
                      key={`product-${index}`}
                      className="w-full md:w-6/12 lg:w-4/12 px-4"
                    >
                      <div className="w-full shadow-xl max-w-sm m-auto">
                        <div
                          className="w-full"
                          role="button"
                          tabIndex={-1}
                          onClick={handleClick}
                        >
                          <GatsbyImage
                            fluid={{
                              ...product.productImage.childImageSharp.fluid,
                              aspectRatio: 5 / 4,
                            }}
                          />
                        </div>
                        <div className="bg-white p-2">
                          <p className="font-bold mb-1 truncate">
                            {product.name}
                          </p>
                          <h5 className="font-bold text-xl mb-1">
                            ${convertCentsToCurrency(product.minPrice)}
                          </h5>
                          <Link
                            className="block w-full text-center shadow-sm bg-teal-600 hover:bg-teal-500 focus:shadow-outline focus:outline-none text-white py-1 rounded"
                            to={`${CONTACT_US_ROUTE}#${CONTACT_FORM_ID}`}
                            state={{
                              initialContactMessage,
                            }}
                          >
                            REQUEST MORE INFO
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div>
                <p className="text-center text-gray-700">
                  No items for sale at this time. Please check back later!
                </p>
              </div>
            )}
          </div>
        </LayoutContainer>
      </section>
      <DisplayDialog activeItem={activeItem} setActiveItem={setActiveItem} />
    </Layout>
  )
}
